import veranstaltungen from './Veranstaltungen25.webp';
import './App.css';

function App() {
  return (
    <div className="App">      
      <body className="App-body">
        <p>
        <img src={veranstaltungen} className="App-veranstaltungen" alt="veranstaltungen" />
        </p>
      </body>

      <footer className="App-footer">
        <p>
        impressum: verantwortlich für die Inhalte; Dominik Fritsch, Böhmerwaldstraße 51, 93105 Tegernheim, 0176/45640927
        </p>
      </footer>
    </div>
  );
}

export default App;
